<template>
  <div class="about">
    <h1 
      v-if="currentRoute === 'Welcome'"
      class="px-6 pt-6 pb-4 text-2xl sm:px-10 lg:px-16 title"
    >
      Welcome
    </h1>

    <div class="flex flex-wrap w-full px-6 sm:px-10 lg:px-16 overflow-hidden">
      <!-- div
        :data-tf-hidden="`user_id=${user_id}, looker_id=${looker_id}`"
        :data-tf-widget="getTypeformId"
        class="c4-theme-typeform-welcome"
        data-tf-hide-headers
        data-tf-iframe-props="title=NPS_DataShopper_rating_homepage_v2"
        data-tf-medium="snippet"
        data-tf-opacity="0" 
      / -->
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import useUI from '@c4-links/vuejs-utilities/src/features/useUI'
import useUser from '@c4-links/vuejs-utilities/src/features/useUser'

export default {
  name: 'WelcomePage',

  setup () {
    const { showMenu } = useUI()
    const { user } = useUser()

    const currentRoute = computed(() => {
      return useRoute().name
    })

    const getTypeformId = computed(() => {
      if (process.env.VUE_APP_ENV === "production") {
        return "u0NMJHMC"
      } else {
        return "tzS1OaTu"
      }
    })

    const checkAlreadyVisibleTypeform = () => {
      if (localStorage) {
        const alreadyVisibleTypeForm = localStorage.getItem("alreadyVisibleTypeForm")
        if (alreadyVisibleTypeForm === null) {
          localStorage.setItem("alreadyVisibleTypeForm", true)
          return false
        }
        return true
      }
      return false
    }

    const showTypeform = !checkAlreadyVisibleTypeform()

    const onClickSupply = ()  => {
        showMenu.value = true
    }

    /*onMounted(() => {
      showMenu.value = true
      const recaptchaScript = document.createElement("script")
      recaptchaScript.setAttribute("src", "//embed.typeform.com/next/embed.js")
      document.head.appendChild(recaptchaScript)
    })*/

    return {
      showTypeform,
      getTypeformId,
      user_id: user.value.oidc.Gntguid,
      looker_id: user.value.lookerUserId,
      showMenu,
      currentRoute,
      canAccessSupply: user.value.canAccessSupply,
      onClickSupply
    }
  }
}
</script>

<style scoped>
.title {
  color: #3a4245;
}
.div-info{
  margin: 0 65px; 
  padding: 0;
}
.list-disc-info{
  list-style: disc;
  margin: 0 30px; 
  padding: 0;
}

.c4-theme-typeform-welcome {
  width: 100%;
  max-width: 1000px;
  max-height: 600px;
  height: 600px;
  background: #235695;
  margin: 48px auto;
}

</style>
