<template>
  <div>
    <Loader
      v-if="fetchUserLoading"
      :fixed="true"
    />
    <div v-else>
      <Alert
        v-if="isError && !fetchUserLoading"
        :summary="lastError.message"
        :details="JSON.stringify(lastError.details)"
        @close="clickCloseAlert"
      />
      <router-view />
    </div>
  </div>
</template>

<script>
import useError from '@c4-links/vuejs-utilities/src/features/useError'
import useUser from '@c4-links/vuejs-utilities/src/features/useUser'

export default {
  name: 'App',

  setup () {
    const { fetchUserLoading } = useUser()
    const { isError, lastError, clearError } = useError()

    const clickCloseAlert = () => {
      clearError()
    }

    return {
      isError,
      lastError,
      clickCloseAlert,
      fetchUserLoading
    }
  }
}
</script>
