// Copyright Carrefour(2022)
//

/* eslint-disable vue/one-component-per-file */

import { h, ref, defineComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import useUser from '@c4-links/vuejs-utilities/src/features/useUser'
import usePermissions from '@/features/usePermissions'
import WelcomePage from '@/components/Pages/WelcomePage'
import useLogo from '@/features/useLogo'

const MenuRegion = () => import(
  /* webpackChunkName: "menu-region" */
  '@/components/Regions/MenuRegion.vue'
)
const SelectionRegion = () => import(
  '@/components/Regions/SelectionRegion.vue'
)
const LoginPage = () => import(
  /* webpackChunkName: "login-page" */
  '@/components/Pages/LoginPage.vue'
)
const LoginPageDev = () => import(
  /* webpackChunkName: "login-page" */
  '@/components/Pages/LoginPageDev.vue'
)
const DefaultLayout = () => import(
  /* webpackChunkName: "layouts" */
  '@c4-links/vuejs-utilities/src/components/TailWind/Layouts/DefaultLayout.vue'
)
const FullscreenLayout = () => import(
  /* webpackChunkName: "layouts" */
  '@c4-links/vuejs-utilities/src/components/TailWind/Layouts/FullscreenLayout.vue'
)
const PageLayout = () => import(
  /* webpackChunkName: "layouts" */
  '@c4-links/vuejs-utilities/src/components/TailWind/Layouts/PageLayout.vue'
)
const FiltersRegion = () => import(
  /* webpackChunkName: "filters-region" */
  '@c4-links/vuejs-utilities/src/components/TailWind/Regions/FiltersRegion.vue'
)
const FiltersButtonRegion = () => import(
  /* webpackChunkName: "filters-region" */
  '@c4-links/vuejs-utilities/src/components/TailWind/Regions/FiltersButtonRegion.vue'
)
const HeaderRegion = () => import(
  /* webpackChunkName: "header-region" */
  '@c4-links/vuejs-utilities/src/components/TailWind/Regions/HeaderRegion.vue'
)
const HeaderPrependRegion = () => import(
  /* webpackChunkName: "header-region" */
  '@c4-links/vuejs-utilities/src/components/TailWind/Regions/HeaderPrependRegion.vue'
)
const MainContentRegion = () => import(
  /* webpackChunkName: "main-region" */
  '@c4-links/vuejs-utilities/src/components/TailWind/Regions/MainContentRegion.vue'
)
const SupportPage = () => import(
  /* webpackChunkName: "support-page" */
  '@/components/Pages/SupportPage.vue'
)

const DashboardEmbedSupply = () => import('@/components/Pages/DashboardEmbedPage.vue')

const { isLogged, getUser, handleTokenReply, user } = useUser()
const { hasAccess, checkAuthentication, checkSupplyPermissions } = usePermissions()
const { getCompanyLogo } = useLogo()

const titlePage = ref('')
const username = ref('')
const company = ref('')
const logoPath = ref(null)
const logoStyle = ref('')

const isDevEnv = () => {
  return window.location.hostname === 'dev.supply.growth.carrefour.com' ||
    window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost'
}

const RedirectIfLogged = () => {
  if (isLogged.value) {
    return { name: 'Welcome' }
  }
  return true
}

const computeTitlePage = (routeLocation) => {
  let title = ''

  if (routeLocation.name === 'Welcome') {
    title = routeLocation.name.replace('/', '').replaceAll('-', ' ')
  } else {
    title = routeLocation.path.replace('/', '').replaceAll('-', ' ')
  }
  title = title.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

  return title
}

const computeLogo = (companyName) => {
  const publicPath = process.env.BASE_URL
  const logoItem = getCompanyLogo(companyName)
  if (logoItem) {
    return {
      path: `${publicPath}images/suppliers-logos/${logoItem.name}.png`,
      style: logoItem.style
    }
  }
  return {
    path: null,
    style: ''
  }
}

const routes = [
  {
    path: '/datashopper/auth/handle',
    component: defineComponent({
      render() {
        window.location.href = process.env.VUE_APP_API_BASE_URL +
          window.location.pathname +
          window.location.search
        return h('div',
          {
            class:
            {
              'animate-pulse': true,
              'pt-48': true,
              'px-32': true,
              'opacity-75': true,
              'text-center': true
            }
          },
          'Redirecting to complete login...')
      }
    })
  },
  {
    path: '/health',
    name: 'Health',
    component: defineComponent({
      render() {
        window.location.href = process.env.BASE_URL + 'health.json'
        return h('pre', {}, JSON.stringify({ status: 'ok' }))
      }
    })
  },
  {
    path: '',
    component: DefaultLayout,
    props: route => {
      return {
        ...route.params,
        enable: user.value.supply.active,
      }
    },
    children: [
      {
        path: '',
        props: {
          'header': route => {
            return {
              ...route.params,
              title: titlePage.value,
              logo: logoPath.value,
              logoStyle: logoStyle.value,
              username: username.value,
              company: company.value
            }
          },
          'filters': route => {
            return {
              ...route.params,
              mode: 'supply'
            }
          },
          'menu': route => {
            return {
              ...route.params,
              title: 'Supply Menu',
              mode: 'supply',
              groups: [
                'Overall Insights',
                'Stores Operational Insights',
                'Stores & Warehouses Insights',
                'Warehouses Operational Insights',
                'Overall Benchmarks',
                'Documentation',
              ]
            }
          },
        },
        components: {
          header: HeaderRegion,
          menu: MenuRegion,
          'filters': FiltersRegion,
          'main-content': MainContentRegion
        },
        children: [
          {
            path: '',
            components: {
              'header-prepend': HeaderPrependRegion,
              'main-content': PageLayout
            },
            children: [
              {
                path: '',
                redirect: { name: 'Welcome' }
              },
              {
                path: 'no-access',
                name: 'NoAccess',
                components: {
                  'main-content': defineComponent({
                    render() {
                      return h('div', {}, 'No Access')
                    }
                  })
                },
                meta: { requiresAuth: true }
              },
              {
                path: 'welcome',
                name: 'Welcome',
                components: {
                  'main-content': WelcomePage
                },
                meta: { requiresAuth: true }
              },
              {
                path: 'support',
                name: 'Support',
                components: {
                  'main-content': SupportPage
                },
                meta: { requiresAuth: true }
              },
              {
                path: 'user-guide',
                name: 'User Guide',
                beforeEnter(to, from) {
                  window.open('https://docs.growth.carrefour.com/supply-chain-insights/', '_blank')
                  return false
                },
                meta: { requiresAuth: true }
              },              
              {
                path: ':slug',
                name: 'DashboardEmbed',
                props: {
                  'main-content': route => {
                    return {
                      ...route.params
                    }
                  },
                  'inpage-header': route => {
                    return {
                      ...route.params
                    }
                  }
                },
                components: {
                  'header-prepend': FiltersButtonRegion,
                  'inpage-header': SelectionRegion,
                  'main-content': DashboardEmbedSupply
                },
                meta: {
                  requiresAuth: true,
                  requireSupplyAccess: true,
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: FullscreenLayout,
    children: [
      {
        path: '',
        components: {
          'main-content': MainContentRegion
        },
        children: [
          {
            path: 'login',
            name: 'Login',
            components: {
              'main-content': isDevEnv() ? LoginPageDev : LoginPage
            },
            beforeEnter: [RedirectIfLogged]
          }
        ]
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: defineComponent({ render() { return h('div', '404') } })
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash
      }
    }
  }
})

router.beforeEach(async (to, from, next) => {

  if (to.query.token) {
    await handleTokenReply({ token: to.query.token })
  }

  const userSupply = await getUser()

  username.value = userSupply?.oidc ? userSupply.oidc.name : ''
  company.value = userSupply?.oidc?.dwexternalcompany ? userSupply.oidc.dwexternalcompany[0] : ''
  const computedLogo = computeLogo(company.value)
  logoPath.value = computedLogo.path
  logoStyle.value = computedLogo.style
  if (to.name !== 'User Guide') {
    titlePage.value = computeTitlePage(to)
  } 
  const selector = to.params.slug ? { slug: to.params.slug } : { route: to.name }
  if (!await hasAccess(userSupply, selector)) {
    return next({ path: '/' })
  }

  checkAuthentication(to, next, isLogged)
  checkSupplyPermissions(to, next, userSupply)

  next()
})

export default router
