// Copyright Carrefour(2022)
//

const companyLogoMapping = {
  'PROCTER AND GAMBLE': {
    name: 'PG',
    style: 'white'
  },
  'RED BULL': {
    name: 'REDBULL',
    style: 'color'
  },
  NESTLE: {
    name: 'NESTLE',
    style: 'white'
  },
  PEPSICO: {
    name: 'PEPSICO',
    style: 'white'
  },
  UNILEVER: {
    name: 'UNILEVER',
    style: 'white'
  },
  MONDELEZ: {
    name: 'MONDELEZ',
    style: 'color'
  },
  'GLAXO SMITHKLINE': {
    name: 'GSK',
    style: 'color'
  },
  FRONERI: {
    name: 'FRONERI',
    style: 'white'
  },
  SAVENCIA: {
    name: 'SAVENCIA',
    style: 'white'
  },
  BOLTON: {
    name: 'BOLTON',
    style: 'color'
  },
  'LINDT SPRUNGLI': {
    name: 'LINDT',
    style: 'white'
  },
  KELLOGGS: {
    name: 'KELLOGGS',
    style: 'color'
  },
  CARLSBERG: {
    name: 'CARLSBERG',
    style: 'white'
  },
  MARS: {
    name: 'MARS',
    style: 'white'
  }
}

const getCompanyLogo = (company) => {
  return companyLogoMapping[company]
}

const useLogo = () => {
  return {
    getCompanyLogo
  }
}

export default useLogo
