import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'v-calendar/dist/style.css'
import '@/assets/css/tailwind.css'
import VTooltip from 'v-tooltip'
import Alert from '@c4-links/vuejs-utilities/src/components/Common/Alert.vue'
import AnimatedPing from '@c4-links/vuejs-utilities/src/components/Common/AnimatedPing.vue'
import Loader from '@c4-links/vuejs-utilities/src/components/Common/Loader.vue'
import CarrefourLogo from '@c4-links/vuejs-utilities/src/components/Icons/CarrefourLogo.vue'
import useFilters from '@c4-links/vuejs-utilities/src/features/useFilters'
import VueGtag from 'vue-gtag'

const filters = useFilters()

filters.assignFilterGroups(
    [
        {
            label: 'General',
            group: 'general'
        },
        {
            label: 'Site',
            group: 'site'
        },
        {
            label: 'Product Hierarchy',
            group: 'product',
            categories: [
                { label: 'International', key: 'international' },
                { label: 'Local', key: 'local' }
            ]
        },
        {
            label: 'Other Filters',
            group: 'other'
        }
    ]
)

const app = createApp(App)

app.use(VueGtag, {
    config: {
        id : process.env.VUE_APP_GTM
    }
})

app.use(VTooltip)
app.component("Alert", Alert)
app.component("AnimatedPing", AnimatedPing)
app.component("Loader", Loader)
app.component("CarrefourLogo", CarrefourLogo)

app.use(router)

app.mount('#app')