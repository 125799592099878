// Copyright Carrefour(2022)
//

import find from 'lodash-es/find'

const permissions = [
  /*{
    name: 'Details Dashboards',
    slug: 'detention-stores-supply',
  },
  {
    name: 'Details Dashboards',
    slug: 'detention-details-stores-supply',
  },
  {
    name: 'Details Dashboards',
    slug: 'stocks-stores-supply',
  },
  {
    name: 'Details Dashboards',
    slug: 'service-level-stores-supply',
  },
  {
    name: 'Details Dashboards',
    slug: 'from-warehouse-to-store-supply',
  },
  {
    name: 'Details Dashboards',
    slug: 'stocks-warehouses-supply',
  }*/
]

const hasAccess = (user, selector) => {
  const permission = find(permissions, selector)
  const userPermissions = user?.supply?.permissions || []
  return permission ? userPermissions.includes(permission.name) : true
}

const checkAccessProfile = (user, profiles, has_benchmark) => {
  const cookieTestQaName = "accessTestQa"
  let cookieTestQa = false

  const cookies = document.cookie.split("; ")
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=")
    if (cookie[0] === cookieTestQaName) {
      cookieTestQa = cookie[1]
    }
  }

  let regularprofiles = []
  if (typeof profiles === 'string') {
    regularprofiles = Array.from(profiles)
  }else{
    regularprofiles = [...profiles]
  }

  const userProfile = user?.supply?.profile || 'basic'
  const userBenchmark = user?.supply?.benchmark || false
  const checkProfile = regularprofiles.find(item => item === userProfile)

  if(has_benchmark && !userBenchmark){
    return false
  }

  if(cookieTestQa) {
    return true
  }

  if (checkProfile){
    return true
  }else{
    return false
  }

}

const checkAuthentication = (selector, nextSelector, isLogged) => {
  if (selector.meta.requiresAuth) {
    if (!isLogged.value) {
      return nextSelector({ name: 'Login' })
    }
  }
}

const checkSupplyPermissions = (selector, nextSelector, user) => {
  if (selector.meta.requireSupplyAccess) {
    if (!user.canAccessSupply) {
      return nextSelector({ path: '/' })
    }
  }
}

const usePermissions = () => {
  return {
    hasAccess,
    checkSupplyPermissions,
    checkAuthentication,
    checkAccessProfile,
  }
}

export default usePermissions
